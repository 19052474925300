import React from "react";
import "../css/Footer.css"

export default function Footer() {
  return (
    <div className="footer-footer">
      <ul><b>Company</b><li><a href="https://ivrynetwork.ca/about">About</a></li><li><a href="https://wiki.ivrynetwork.ca/index.php/FAQ">FAQ</a></li><li><a href="https://wiki.ivrynetwork.ca">Wiki</a></li><li><a>	</a></li></ul>
      <ul><b>Legal</b><li><a href="https://wiki.ivrynetwork.ca/index.php/Legal#Terms_of_Service">Terms Of Service</a></li><li><a href="https://wiki.ivrynetwork.ca/index.php/Legal#Privacy_Policy">Privacy Policy</a></li><li><a href="https://wiki.ivrynetwork.ca/index.php/Legal#Sales_and_Refunds">Sales and Refunds</a></li><li><a href="https://wiki.ivrynetwork.ca/index.php/Legal#Our_Partners">Our Partners</a></li></ul>
      <ul><b>Support</b><li><a href="https://discord.gg/ppn2U99">Discord</a></li><li><a a href="https://wiki.ivrynetwork.ca/index.php/capstone">Capstone</a></li><li><a>	</a></li><li><a>	</a></li></ul>
    </div>

  );
};
