//base imports required to run
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import '../css/Profile.css'
//preset imports to important items
import Footer from "../helpers/Footer";
import Header from "../helpers/Header";
import Copyright from "../helpers/Copyright";
import { logout } from "../actions/auth";
//track page loading for top of page loading bar
window.addEventListener("beforeunload",function(e){
  document.body.className = "page-loading";
},false);
//start of page Profile
const Profile = () => {
//is user logged in?
const { isLoggedIn } = useSelector(state => state.auth);
const { user: currentUser } = useSelector((state) => state.auth);
const dispatch = useDispatch();
const logOut = () => {
  dispatch(logout());
};

    if (isLoggedIn) {

      return (
          <div>
            <div id="Navigation" className="Navigation">
            <Header />  
            </div>

            <div id="Content">
              <body className="profile-body">
              <div class="profile-row">
                <h1>Account Settings</h1>
                <p>Manage your account's details.</p>
                <br/>
                <h2>Account Information</h2>
                <p>Your personal information, account support ID and password hash.</p>
                <p><b>ID: </b>{currentUser.id}</p>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Username</label>
                <input
                  className="profile-text-box-input-editable"
                  type="text"
                  value={currentUser.username}
                  readOnly/>
                  <button className="profile-edit-button"><svg width="27" height="27" viewBox="0 0 27 27" class="css-h0onl6"><path d="M10.125 14.4891V16.8757L12.5115 16.8757L22.5 6.88674C23.159 6.2277 23.159 5.15918 22.5 4.50014C21.841 3.8411 20.7725 3.8411 20.1135 4.50014L10.125 14.4891Z"></path><path d="M6.75 6.75027H13.5V4.50016H6.75C5.50736 4.50016 4.5 5.50757 4.5 6.75027V20.2509C4.5 21.4936 5.50736 22.501 6.75 22.501H20.25C21.4926 22.501 22.5 21.4936 22.5 20.2509V13.5006H20.25V20.2509H6.75V6.75027Z"></path></svg></button>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Email Address</label>
                <input
                  className="profile-text-box-input-editable"
                  type="text"
                  value={currentUser.email}
                  readOnly/>
                  <button className="profile-edit-button"><svg width="27" height="27" viewBox="0 0 27 27" class="css-h0onl6"><path d="M10.125 14.4891V16.8757L12.5115 16.8757L22.5 6.88674C23.159 6.2277 23.159 5.15918 22.5 4.50014C21.841 3.8411 20.7725 3.8411 20.1135 4.50014L10.125 14.4891Z"></path><path d="M6.75 6.75027H13.5V4.50016H6.75C5.50736 4.50016 4.5 5.50757 4.5 6.75027V20.2509C4.5 21.4936 5.50736 22.501 6.75 22.501H20.25C21.4926 22.501 22.5 21.4936 22.5 20.2509V13.5006H20.25V20.2509H6.75V6.75027Z"></path></svg></button>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Prefered Language</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value="English - Default"
                  readOnly/>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Password Hash</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.password}
                  readOnly/>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Session Token</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.accessToken.substring(0, 40)} 
                  readOnly/>
                </div> 
              </div>
              <div className="profile-row2">
                <h2>Transaction Information</h2>
                <p>Your account payment details, transactions and ID.</p>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Customer ID</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.customer_Id}
                  readOnly/>
                </div>

                <form method="POST" action="https://api.ivrynetwork.ca/create-portal-session">
                <input type="hidden" name="customer" value={currentUser.customer_Id}  />
                <button type="submit">Manage billing</button>
                </form>

              </div>
              <div className="profile-row3">
                <h2>Subscription Information</h2>
                <p>Your currently active subscriptions.</p>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">VPN</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.vpn}
                  readOnly/>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">SQL</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.sql}
                  readOnly/>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Server Hosting</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value={currentUser.hosting}
                  readOnly/>
                </div>
                <div className="profile-text-box-container">
                <label className="profile-text-box-label">Streaming</label>
                <input
                  className="profile-text-box-input"
                  type="text"
                  value="N/A"
                  readOnly/>
                </div>
              </div>
              </body>
              
              <Footer />  

              <Copyright />
            </div>
          </div>
        );
      } 
      
    else {
        return <Redirect to="https://ivrynetwork.ca/auth/signup" />;
    } 
}
  
export default Profile;
