import React from "react";
import "../css/Footer.css"

export default function Copyright() {
  return (

<div className="footer-copyright"><b><img width="13.5px" alt="CA Flag" src="https://ivrynetwork.ca/images/country.png"/> Copyright © 2023 Ivry Network Ltd</b></div>

    );
};
