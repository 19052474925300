import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import './css/App.css';

import { history } from "./helpers/history";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Solutions from "./pages/Solutions";
import Signup from "./pages/Signup";
import Store from "./pages/Store";
import About from "./pages/About";
import Profile from "./pages/Profile";

const App = () => {

  const { isLoggedIn } = useSelector(state => state.auth);

// eslint-disable-next-line
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);
// eslint-disable-next-line
  const logOut = () => {
    dispatch(logout());
  };
  
  if (isLoggedIn) {


      return (
        <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/auth/signin" component={Login}/>
          <Route exact path="/auth/signup" component={Signup}/>
          <Route exact path="/store" component={Store}/>
          <Route exact path="/solutions" component={Solutions}/>
          <Route exact path="/about" component={About}/>

          
          <Route exact path="/profile" component={Profile}/>

          <Redirect path="/"/>
        </Switch>
      </Router>
    );
  }
    else {

    return (
      <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/auth/signin" component={Login}/>
        <Route exact path="/auth/signup" component={Signup}/>
        <Route exact path="/store" component={Store}/>
        <Route exact path="/solutions" component={Solutions}/>
        <Route exact path="/about" component={About}/>
        
        
        <Route exact path="/profile" component={() => { window.location.href = 'https://ivrynetwork.ca/'; return null;}}/>

        <Redirect path="/"/>
      </Switch>
    </Router>
    );
  };
}

export default App;
