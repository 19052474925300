//base imports required to run
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../css/Home.css'
//preset imports to important items
import Footer from "../helpers/Footer";
import Header from "../helpers/Header";
import Headernolog from "../helpers/Headernolog";
import Copyright from "../helpers/Copyright";
import { logout } from "../actions/auth";
//track page loading for top of page loading bar
window.addEventListener("beforeunload",function(e){
  document.body.className = "page-loading";
},false);
//start of page HOME
const Home = () => {
//is user logged in?
const { isLoggedIn } = useSelector(state => state.auth);
const { user: currentUser } = useSelector((state) => state.auth);
const dispatch = useDispatch();
const logOut = () => {
  dispatch(logout());
};
//logged in user data
    if (isLoggedIn) {

      return (
          <div>
            <div>
            <Header />  
            </div>

            <div id="Content">
              <body className="home-body">
              <div className="home-row">
              <center><h6>Ivry Network</h6>
              <h2>Providing secure high quality services</h2></center>   
              </div>
              <div className="home-row2">
                    <h2>Ivry Solutions</h2>
                    <h3>Simple Fast and secure</h3>   
                    <p>Front/Backend Web, Data Management, LoT Devices, API Development, Hardware/Software. </p>      
                    <a href="https://ivrynetwork.ca/solutions"><button>Visit solutions</button></a>
              </div>
              <div className="home-row2">
                    <h2>About us</h2>
                    <h3>A small team with big a dream</h3>      
                    <p>Learn about us, our experiences, and what we <br/> have to offer and so much more below.</p>      
                    <a href="https://ivrynetwork.ca/about"><button>Learn more</button></a>
              </div>
              <div className="home-row3">
                    <h2>Ivry Webstore</h2>
                    <h3>Our Best Quality Products</h3>
                    <p>Great prices on our quality products / services +<br/> fully secure payments through Stripe.</p>
                    <a href="https://ivrynetwork.ca/store"><button>Visit store</button></a>
              </div>
              </body>
            
              <Footer />  

              <Copyright />
            </div>
          </div>
        );
      } 

//Not logged in user data
    else {

      return (
            <div>
              <div id="Navigation" className="Navigation">
              <Headernolog />  
              </div>

              <div id="Content">
                <body className="home-body">
                <div className="home-row">
              <center><h6>Ivry Network</h6>
              <h2>Providing secure high quality services</h2></center>   
              </div>      
              <div className="home-row2">
                    <h2>Ivry Solutions</h2>
                    <h3>Simple Fast and secure</h3>   
                    <p>Front/Backend Web, Data Management, LoT Devices, API Development, Hardware/Software. </p>      
                    <a href="https://ivrynetwork.ca/solutions"><button>Visit solutions</button></a>
              </div>
              <div className="home-row2">
                    <h2>About us</h2>
                    <h3>A small team with big a dream</h3>      
                    <p>Learn about us, our experiences, and what we <br/> have to offer and so much more below.</p>      
                    <a href="https://ivrynetwork.ca/about"><button>Learn more</button></a>
              </div>
              <div className="home-row3">
                    <h2>Ivry Webstore</h2>
                    <h3>Our Best Quality Products</h3>
                    <p>Great prices on our quality products / services +<br/> fully secure payments through Stripe.</p>
                    <a href="https://ivrynetwork.ca/store"><button>Visit store</button></a>
              </div>
                </body>
              
                <Footer />              

                <Copyright />
              </div>
            </div>
          );

    } 
}
  
export default Home;