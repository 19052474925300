import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../css/Solutions.css'
import Axios from "axios";
//preset imports to important items
import Footer from "../helpers/Footer";
import Header from "../helpers/Header";
import Headernolog from "../helpers/Headernolog";
import Copyright from "../helpers/Copyright";

import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import { history } from "../helpers/history";

window.addEventListener("beforeunload",function(e){
  document.body.className = "page-loading";
},false);

const Solutions = () => {

  const { isLoggedIn } = useSelector(state => state.auth);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [data, setOphelia] = useState("");

  useEffect(() => {

    Axios.get("https://api.ivrynetwork.ca/version/ophelia")
    .then((res) => { 
    console.log(res.data)
     setOphelia(res.data);

    })    

    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);
  
  const logOut = () => {
    dispatch(logout());
  };
  
      if (isLoggedIn) {

        return (
          <div>
            <div>
            <Header />  
            </div>

            <div id="Content">
              <body className="solutions-body">
                <div className="solutions-row">
                      <h2>Ophelia VPN V.{data.version}</h2>
                      <h3>Secure and Fast.</h3>
                      <ul>
                        <li><p>Full encryption</p></li>
                        <li><p>Premium speeds upto 1Gbps<a style={{color: "red"}} href="https://wiki.ivrynetwork.ca/index.php/Solutions#VPN_Service">**</a></p></li>
                        <li><p>15+ Servers world wide</p></li>
                        <li><p>Account and subscription required*</p></li>
                      </ul>      
                      <a href="https://ivrynetwork.ca/programs/ophelia.exe"><button className="options_button">Download (Windows x64)</button></a>
                </div>
                <div className="solutions-row2">
                      <h2>Coming Soon</h2>
                      <h3>.</h3>
                      <ul>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                      </ul>      
                      <a href="https://ivrynetwork.ca"><button className="options_button">Coming Soon</button></a>
                </div>
              </body>

              <Footer />              

              <Copyright />
          </div>
        </div>
          );
        }
        else {

          return(
            <div>
            <div>
              <Headernolog/>
            </div>

            <div id="Content">
              <body className="solutions-body">
              <div className="solutions-row">
                      <h2>Ophelia VPN V.{data.version}</h2>
                      <h3>Secure and Fast. </h3>
                      <ul>
                        <li><p>Full encryption</p></li>
                        <li><p>Premium speeds upto<a style={{color: "red"}} href="https://wiki.ivrynetwork.ca/index.php/Solutions#VPN_Service">**</a></p></li>
                        <li><p>15+ Servers world wide</p></li>
                        <li><p>Account and subscription required*</p></li>
                      </ul>      
                      <a href="https://ivrynetwork.ca/auth/signup"><button className="options_button">Account Required</button></a>
                </div>
                <div className="solutions-row2">
                      <h2>Coming Soon</h2>
                      <h3>.</h3>
                      <ul>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                        <li><p>.</p></li>
                      </ul>      
                      <a href="https://ivrynetwork.ca/auth/signup"><button className="options_button">Account Required</button></a>
                </div>
              </body>

              <Footer />              

              <Copyright />
          </div>
        </div>
          );

        }
};
export default Solutions;
