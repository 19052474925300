//base imports required to run
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../css/Store.css'
//preset imports to important items
import Footer from "../helpers/Footer";
import Header from "../helpers/Header";
import Headernolog from "../helpers/Headernolog";
import Copyright from "../helpers/Copyright";
import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import { history } from "../helpers/history";
//track page loading for top of page loading bar
window.addEventListener("beforeunload",function(e){
  document.body.className = "page-loading";
},false);

const Store = () => {

  const [data, setCopyright] = useState("");  

  const { isLoggedIn } = useSelector(state => state.auth);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);
  
  const logOut = () => {
    dispatch(logout());
  };

function showDiv() {
    document.getElementById('VPNOPTIONS').style.display = "block";
 }

function showDiv2() {
  document.getElementById('SQLOPTIONS').style.display = "block";
}

function showDiv3() {
  document.getElementById('HOSTOPTIONS').style.display = "block";
}

 function hideDiv() {
  document.getElementById('VPNOPTIONS').style.display = "none";
}

function hideDiv2() {
  document.getElementById('SQLOPTIONS').style.display = "none";
}

function hideDiv3() {
  document.getElementById('HOSTOPTIONS').style.display = "none";
}

  if (isLoggedIn) {

    return (
      <div>
        <div>
          <Header />  
        </div>

        <div id="Content">
          <body className="store-body">
          <div id="VPNOPTIONS"  style={{display: "none"}} class="card_options" >
                    <ul>
                    <li><button class="card__button2" onClick={hideDiv}>X</button></li>
                    <li><b>VPN Options</b></li>
                    <br/>
                    <li><b>VPN</b> <p class="card__info"><b>$5 / Month</b></p> <p><b>Product Details:</b></p> <ul> <li><p>Full encryption</p></li> <li><p>No data, speed or connection limits</p></li> <li><p>Premium speeds from 1Gbps to 10Gbps<a style={{color: "red"}} href="https://wiki.ivrynetwork.ca/index.php/Solutions#VPN_Service">**</a></p></li> <li><p>Low latency 10ms to 100ms</p></li> <li><p>Full DDoS Protection</p></li> <li><p>15+ Servers world wide</p></li> </ul> <a href="https://buy.stripe.com/test_28ocNib13brYb8A5kk"><button class="card__button">Coming Soon</button></a></li>
                    </ul>
                  </div>
          <div id="SQLOPTIONS"  style={{display: "none"}} class="card_options" >
                    <ul>
                    <li><button class="card__button2" onClick={hideDiv2}>X</button></li>
                    <li> <b>SQL Options</b></li>
                    <br/>
                    <li><b>Classic SQL</b> <p class="card__info"><b>$5 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    <br/>
                    <li><b>Standard SQL</b> <p class="card__info"><b>$9.99 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    <br/>
                    <li><b>Pro SQL</b> <p class="card__info"><b>$15 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    </ul>
                  </div>
          <div id="HOSTOPTIONS"  style={{display: "none"}} class="card_options" >
                    <ul>
                    <li><button class="card__button2" onClick={hideDiv3}>X</button></li>
                    <li> <b>Hosting Options</b></li>
                    <br/>
                    <li><b>Minecraft</b> <p class="card__info"><b>$3.99 - 20 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    <br/>
                    <li><b>Unturned</b> <p class="card__info"><b>$5 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    <br/>
                    <li><b>Code</b> <p class="card__info"><b>$2.50 - 7.50 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    <br/>
                    <li><b>Custom</b> <p class="card__info"><b>$2.50 - 20 / Month</b></p> <p>Click purchase for details.</p> <button class="card__button">Coming Soon</button></li>
                    </ul>
                  </div>
          <div class="container">
            <div class="card__container">
              <div>
                <div class="card__content">
                  <h3 class="card__header">VPN Service</h3>
                  <p class="card__info">Click purchase for details.</p>
                  <p class="card__info"><b>$5 / Month</b></p>
                  <button class="card__button" onClick={showDiv}>Purchase</button>
                </div>
              </div>
              <div>
                <div class="card__content">
                  <h3 class="card__header">SQL Database Hosting</h3>
                  <p class="card__info">Click options for details.</p>
                  <p class="card__info"><b>$5 - 15 / Month</b></p>
                  <button class="card__button" onClick={showDiv2}>Options</button>
                </div>
              </div>
              <div>
                <div class="card__content">
                  <h3 class="card__header">Server Hosting</h3>
                  <p class="card__info">Click options for details.</p>
                  <p class="card__info"><b>$2.50 - 20 / Month</b></p>
                  <button class="card__button" onClick={showDiv3}>Options</button>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="card__container">
              <div>
                <div class="card__content">
                  <h3 class="card__header">Support</h3>
                  <p class="card__info">Having a technical issue? were here to help contact us below and let us fix it.</p>
                  <p class="card__info"><b>Free</b></p>
                  <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                </div>
              </div>
              <div>
                <div class="card__content">
                  <h3 class="card__header">Consult</h3>
                  <p class="card__info">Think you have a million dollar idea? we can help you and that idea strive.</p>
                  <p class="card__info"><b>Upon Request</b></p>
                  <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                </div>
              </div>
              <div>
                <div class="card__content">
                  <h3 class="card__header">Hire us</h3>
                  <p class="card__info">Want a service from us? contact us below and we can negotiate a price.</p>
                  <p class="card__info"><b>Upon Request</b></p>
                  <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                </div>
              </div>
            </div>
          </div>
          </body>

          <Footer />  

          <Copyright />
      </div>
    </div>
      );
    }
    else {
      return (
        <div>
          <div>
            <Headernolog/>
          </div>
  
          <div id="Content">
            <body className="store-body">
            <div class="container">
              <div class="card__container">
                <div>
                  <div class="card__content">
                    <h3 class="card__header">VPN Service</h3>
                    <p class="card__info">Click purchase for details.</p>
                    <p class="card__info"><b>$5 / Month</b></p>
                    <button class="card__button">Account required</button>
                  </div>
                </div>
                <div>
                  <div class="card__content">
                    <h3 class="card__header">SQL Database Hosting</h3>
                    <p class="card__info">Click options for details.</p>
                    <p class="card__info"><b>$5 - 15 / Month</b></p>
                    <button class="card__button">Account required</button>
                  </div>
                </div>
                <div>
                  <div class="card__content">
                    <h3 class="card__header">Minecraft Server Hosting</h3>
                    <p class="card__info">Click options for details.</p>
                    <p class="card__info"><b>$2.50 - 20 / Month</b></p>
                    <button class="card__button">Account required</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="card__container">
                <div>
                  <div class="card__content">
                    <h3 class="card__header">Support</h3>
                    <p class="card__info">Having a technical issue? were here to help contact us below and let us fix it.</p>
                    <p class="card__info"><b>Free</b></p>
                    <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                  </div>
                </div>
                <div>
                  <div class="card__content">
                    <h3 class="card__header">Consult</h3>
                    <p class="card__info">Think you have a million dollar idea? we can help you and that idea strive.</p>
                    <p class="card__info"><b>Upon Request</b></p>
                    <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                  </div>
                </div>
                <div>
                  <div class="card__content">
                    <h3 class="card__header">Hire us</h3>
                    <p class="card__info">Want a service from us? contact us below and we can negotiate a price.</p>
                    <p class="card__info"><b>Upon Request</b></p>
                    <a href="mailto: contact@ivrynetwork.ca"><button class="card__button">Contact</button></a>
                  </div>
                </div>
              </div>
            </div>
            </body>
  
  
  
            <Footer />  

            <Copyright />
        </div>
      </div>
        );
    }
};
export default Store;
